import React from 'react';
import cntl from 'cntl';

const hiddenWrapper = cntl`
  hidden
  lg:block
  lg:absolute
  lg:inset-0
`;

const illustration = cntl`
  absolute
  top-0
  left-1/2
  transform
  translate-x-64
  -translate-y-8
`;

const GrayRects: React.FC = () => {
  return (
    <div className={hiddenWrapper} aria-hidden="true">
      <svg
        className={illustration}
        width={640}
        height={550}
        fill="none"
        viewBox="0 0 640 550"
      >
        <defs>
          <pattern
            id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
            x={118}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              fill="currentColor"
              className="text-gray-200"
            />
          </pattern>
        </defs>
        <rect
          x={118}
          width={640}
          height={550}
          fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
        />
      </svg>
    </div>
  );
};

export default GrayRects;
