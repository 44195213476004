import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import useAutocomplete from '../../runtime/hooks/useAutocomplete';
import {
  CustomInputProps,
  InputVariationEnum,
  PlacesAutocompleteValue,
} from '../../@types';

interface AdditionalProps {
  element: React.ForwardRefExoticComponent<
    CustomInputProps &
      React.HTMLProps<HTMLInputElement> &
      React.RefAttributes<HTMLInputElement>
  >;
  defaultPlace?: PlacesAutocompleteValue;
  variation?: InputVariationEnum;
}

const MapAutocompleteField: React.FC<
  AdditionalProps & FieldHookConfig<PlacesAutocompleteValue | null>
> = props => {
  const [, meta, helpers] = useField(props);
  const { error, value } = meta;
  const { setValue } = helpers;

  const ref = useAutocomplete(
    ({ formatted_address, place_id, address_components }) => {
      if (typeof formatted_address === 'undefined') {
        setValue(null);
        return;
      }

      setValue({
        formatted_address,
        place_id,
        address_components,
      });
    }
  );

  const Element = props.element;

  return (
    <Element
      error={error}
      ref={ref}
      defaultValue={value?.formatted_address}
      variation={props.variation}
      disabled={props.disabled}
      placeholder={props.placeholder}
      type="text"
    />
  );
};

export default MapAutocompleteField;
