import { PlacesAutocompleteValue } from '../@types';
import isRuntime from './is-runtime';

const storageItemName = 'addressFromIndexPage';

export function setAddress(address: PlacesAutocompleteValue): void {
  if (!isRuntime()) {
    return;
  }

  localStorage.setItem(storageItemName, JSON.stringify(address));
}

export function getAddress(): PlacesAutocompleteValue | null {
  if (!isRuntime()) {
    return null;
  }

  const item = localStorage.getItem(storageItemName);

  return item !== null ? JSON.parse(item) : null;
}
