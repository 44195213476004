import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import isRuntime from '../is-runtime';

const apiKey = process.env.GATSBY_G_APIS_KEY;

export default function useAutocomplete(
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void
): React.RefObject<HTMLInputElement> {
  if (!isRuntime()) {
    return React.createRef();
  }

  const options: google.maps.places.AutocompleteOptions = {
    types: ['address'],
    fields: ['formatted_address', 'place_id', 'address_components'],
    componentRestrictions: {
      country: 'us',
    },
  };

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey,
    onPlaceSelected,
    options,
  });

  return ref;
}
