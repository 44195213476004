import { PlacesAutocompleteValue } from '../@types';
import { ContentfulServiceArea, ItemList } from '../@types/contentful';

interface AddressDetails {
  city?: string;
  county?: string;
  state?: string;
}

export default function isPlaceSupported(
  serviceAreas: ItemList<ContentfulServiceArea>,
  place: PlacesAutocompleteValue
): boolean {
  const extractedDetails: AddressDetails = {};

  if (typeof place?.address_components !== 'undefined') {
    for (const component of place.address_components) {
      if (component.types.indexOf('administrative_area_level_1') !== -1) {
        extractedDetails.state = component.short_name;
      } else if (
        component.types.indexOf('administrative_area_level_2') !== -1
      ) {
        extractedDetails.county = component.short_name;
      } else if (component.types.indexOf('locality') !== -1) {
        extractedDetails.city = component.short_name;
      }
    }
  }

  // Find extracted address in a list
  const areaIndex = serviceAreas.items.findIndex(area => {
    if (area.state !== extractedDetails.state) {
      return false;
    } else if (
      extractedDetails.county !== undefined &&
      extractedDetails.county !== area.county
    ) {
      return false;
    } else if (
      extractedDetails.city !== undefined &&
      extractedDetails.city !== area.city
    ) {
      return false;
    }

    return true;
  });

  return areaIndex !== -1;
}
